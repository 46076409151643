exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-auth-change-password-js": () => import("./../../../src/pages/auth/changePassword.js" /* webpackChunkName: "component---src-pages-auth-change-password-js" */),
  "component---src-pages-auth-login-js": () => import("./../../../src/pages/auth/login.js" /* webpackChunkName: "component---src-pages-auth-login-js" */),
  "component---src-pages-auth-recovery-index-js": () => import("./../../../src/pages/auth/recovery/index.js" /* webpackChunkName: "component---src-pages-auth-recovery-index-js" */),
  "component---src-pages-auth-registration-js": () => import("./../../../src/pages/auth/registration.js" /* webpackChunkName: "component---src-pages-auth-registration-js" */),
  "component---src-pages-connections-create-index-js": () => import("./../../../src/pages/connections/create/index.js" /* webpackChunkName: "component---src-pages-connections-create-index-js" */),
  "component---src-pages-connections-edit-[index]-js": () => import("./../../../src/pages/connections/edit/[index].js" /* webpackChunkName: "component---src-pages-connections-edit-[index]-js" */),
  "component---src-pages-connections-index-js": () => import("./../../../src/pages/connections/index.js" /* webpackChunkName: "component---src-pages-connections-index-js" */),
  "component---src-pages-docs-index-jsx": () => import("./../../../src/pages/docs/index.jsx" /* webpackChunkName: "component---src-pages-docs-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-integrations-create-js": () => import("./../../../src/pages/integrations/create.js" /* webpackChunkName: "component---src-pages-integrations-create-js" */),
  "component---src-pages-integrations-edit-js": () => import("./../../../src/pages/integrations/edit.js" /* webpackChunkName: "component---src-pages-integrations-edit-js" */),
  "component---src-pages-integrations-filter-for-copy-index-js": () => import("./../../../src/pages/integrations/filterForCopy/index.js" /* webpackChunkName: "component---src-pages-integrations-filter-for-copy-index-js" */),
  "component---src-pages-integrations-filter-for-integration-create-index-js": () => import("./../../../src/pages/integrations/filterForIntegration/create/index.js" /* webpackChunkName: "component---src-pages-integrations-filter-for-integration-create-index-js" */),
  "component---src-pages-integrations-filter-for-integration-edit-index-js": () => import("./../../../src/pages/integrations/filterForIntegration/edit/index.js" /* webpackChunkName: "component---src-pages-integrations-filter-for-integration-edit-index-js" */),
  "component---src-pages-integrations-filter-for-integration-index-js": () => import("./../../../src/pages/integrations/filterForIntegration/index.js" /* webpackChunkName: "component---src-pages-integrations-filter-for-integration-index-js" */),
  "component---src-pages-nocodb-index-js": () => import("./../../../src/pages/nocodb/index.js" /* webpackChunkName: "component---src-pages-nocodb-index-js" */),
  "component---src-pages-profile-index-js": () => import("./../../../src/pages/profile/index.js" /* webpackChunkName: "component---src-pages-profile-index-js" */),
  "component---src-pages-tariff-check-index-js": () => import("./../../../src/pages/tariff/check/index.js" /* webpackChunkName: "component---src-pages-tariff-check-index-js" */),
  "component---src-pages-tariff-index-js": () => import("./../../../src/pages/tariff/index.js" /* webpackChunkName: "component---src-pages-tariff-index-js" */),
  "component---src-pages-tariff-payment-index-js": () => import("./../../../src/pages/tariff/payment/index.js" /* webpackChunkName: "component---src-pages-tariff-payment-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-docs-js": () => import("./../../../src/templates/docs.js" /* webpackChunkName: "component---src-templates-docs-js" */)
}

