import axios from "axios"
import axiosRetry from "axios-retry"

export const axiosInstance = axios.create({
  // baseURL: process.env.NEXT_BASE_URL_CLOUD_PROD,
  baseURL: process.env.GATSBY_BASE_URL,
  headers: { "Content-Type": "application/json" },
})

axiosRetry(axiosInstance, {
  retries: 2,
  retryDelay: retryCount => {
    return retryCount * 500
  },
  retryCondition: error => {
    return (
      error.code !== "ERR_NETWORK" &&
      error.response &&
      [400].includes(error.response.status)
    )
  },
})

axiosInstance.interceptors.request.use(config => {
  const accessToken = localStorage.getItem("accessTokenNolimCloud")
  if (accessToken) {
    config.headers["Authorization"] = `Bearer ${accessToken}`
  }
  return config
})

axiosInstance.interceptors.response.use(
  res => {
    if (res && res.data && res.status >= 200 && res.status < 300) {
      return res.data
    }
    //при повторном запросе с помощью axios-retry уже возвращается res.data
    return res
  },
  async error => {

    switch (error.code) {
      case "ERR_NETWORK":
        return "Извините, в данный момент сервис недоступен"
      default:
        switch (error.response?.status) {
          case 401:
            localStorage.removeItem("accessTokenNolimCloud")
            localStorage.removeItem("refreshTokenNolimCloud")
            const errorMessage = error.response.data?.title
            if (errorMessage) {
              return errorMessage ?? "Ошибка авторизации"
            } else {
              return "Время авторизации истекло. Войдите заново."
            }
          case 403:
            return error.response?.data?.title || "Ошибка, попробуйте снова"
          case 500:
            return "Извините, в данный момент сервис недоступен"
          default:
            return error.response?.data?.title || "Ошибка, попробуйте снова"
        }
    }
    return Promise.reject(error)
  }
)
