import * as React from "react"
import "../styles/globals.scss"
import AppContextProvider from "../context/AppContext"
import { Script } from "gatsby"
import { ToastProvider } from "../context/ToastContext"
import Toast from "../components/toast/Toast"
import Footer from "../components/footer/Footer"
import Header from "../components/header/Header"
import StaticDataContextProvider from "../context/StaticDataContext"
import IntegrationContextProvider from "../context/IntegrationContext"

const Layout = ({ children }) => {
  return (
    <div className={`layout`}>
      <AppContextProvider>
        <StaticDataContextProvider>
          <IntegrationContextProvider>
            <ToastProvider>
              <Toast />
              <header className={"header"}>
                <Header />
              </header>
              <div className={"content"}>{children}</div>
              <footer className={"footer"}>
                <Footer />
              </footer>
            </ToastProvider>
          </IntegrationContextProvider>
        </StaticDataContextProvider>
      </AppContextProvider>
      <Script src="https://widget.cloudpayments.ru/bundles/cloudpayments.js" />
    </div>
  )
}

export default Layout
